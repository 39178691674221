<template>
  <v-list
    nav
  >
    <v-list-item-group
      v-model="group"
      active-class="list__active white--text"
    >
    
      <v-list-item
        v-for="{icon, target, title} in items"
        :key="target"
        :to="target"
      >
        <v-list-item-icon>
          <v-icon>{{ icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-title">{{ title }}</v-list-item-title>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: 'DrawerList',
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      group: null
    }
  },
}
</script>

<style scoped lang="scss">
  .list__active {
    background: #000;
  }
  .v-list-item--link:before {
    background: unset;
  }
</style>

<template>
  <v-app-bar app :height="isMobile ? 80 : 100" flat scroll-threshold="80" :color="bg">
    <div class="d-flex align-center navbar px-md-10">
      <!--      hamburger icon for mobile-->
      <v-app-bar-nav-icon @click="toggleDrawer()" class="d-flex d-md-none" aria-label="nav-icon"/>
      <!--      logo-->
      <div class="logo d-flex align-center justify-center">
        <img class="logo-img" alt="Mathison Projects logo" src="img/updatedlogo.svg" @click="$router.push('/')"/>
      </div>
      <!--      language selection menu? doesn't appear to be showing up due to v-if false attribute-->
      <v-menu open-on-hover bottom class="d-flex d-md-none" v-if="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              dark
              v-bind="attrs"
              v-on="on"
              text
              class="languages-button app-bar__item"
          >
            {{ selectedLanguage.value }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item
              v-for="(item, index) in languages"
              :key="index"
              @click="selectedLanguageHandler(item)"
              class="app-bar__item"
          >
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!--      navbar items-->
      <v-toolbar-items class="d-none d-md-flex flex-grow-1 justify-end items">
        <v-menu v-for="(menuItem, index) in items" :key="index">
          <template v-slot:activator="{}">
            <v-btn
                :ripple="false"
                v-if="menuItem.title !== 'Get In Touch'"
                class="link"
                text
                :to="menuItem.target"
                :active-class="'activeLink'"
            >
              <span class="text-capitalize">{{ menuItem.title }}</span>
            </v-btn>
          </template>
        </v-menu>
      </v-toolbar-items>
      <!--      contact us button-->
      <v-btn
          color="black"
          dark
          elevation="2"
          large
          rounded
          class="d-none d-md-flex contactButton"
          :to="{ path: '/contact-us', hash: '#form' }"
      >
        Contact Us
      </v-btn>
    </div>
  </v-app-bar>
</template>

<script>
import {mapMutations} from "vuex";
import {gsap} from "gsap";

export default {
  name: "AppBar",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedLanguage: {
        text: "English",
        value: "en",
      },
      languages: [
        {
          text: "English",
          value: "en",
        },
        {
          text: "Spanish",
          value: "es",
        },
        {
          text: "Russian",
          value: "ru",
        },
      ],
      bg: "white",
    };
  },
  computed: {
    name() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return this.$route.meta.title;
        case "md":
        case "lg":
        case "xl":
        default:
          return "Mathison Projects";
      }
    },
    isMobile() {
      return this.$vuetify?.breakpoint?.mobile;
    },
  },
  mounted() {
    const locale = localStorage.getItem("locale");

    if (locale) {
      this.selectedLanguage = this.languages.find(
          (language) => language.value === locale
      );
    }
  },
  methods: {
    ...mapMutations("ui", ["toggleDrawer"]),
    selectedLanguageHandler(language) {
      this.selectedLanguage = language;
      localStorage.setItem("locale", language.value);
      this.$emit("onUpdate");
    },
    animate() {
      const tl = gsap.timeline();

      tl.from(".app-bar__item", {
        duration: 0.5,
        delay: 0.5,
        opacity: 0,
        y: -50,
        stagger: {
          amount: 0.4,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.navbar {
  max-height: 100%;
  width: 100%;

  .logo {
    cursor: pointer;
    width: 40vw;

    @include screen('md') {
      width: 25vw;
    }

    @include screen('lg') {
      width: 15vw;
    }

    @include screen('uw') {
      width: 12vw;
    }
  }

  .logo-img {
    margin-left: -0.7em;
    max-width: 100%;
  }

  .items a, .contactButton {
    font-size: 14px;

    @include screen('lg') {
      font-size: 16px;
    }

    @include screen('uw') {
      font-size: 20px;
    }
  }

  .link {
    position: relative;

    &:hover {
      &:after {
        opacity: 1;
        transform: translateY(0);
      }

      &:before {
        all: revert;
      }
    }

    &:after {
      content: "";
      position: absolute;
      border-radius: 50%;
      width: 6px;
      height: 6px;
      bottom: -8px;
      background: #000;
      opacity: 0;
      transform: translateY(150%);
      transition: all 0.3s ease-in-out;
    }
  }

  .activeLink {
    position: relative;
    font-weight: bold;

    &:before {
      all: revert;
    }

    &:after {
      content: "";
      position: absolute;
      border-radius: 50%;
      width: 6px;
      height: 6px;
      bottom: -8px;
      background: #000;
      opacity: 1;
      transition: all 0.3s ease-in-out;
    }
  }
}
</style>

import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VList,{attrs:{"nav":""}},[_c(VListItemGroup,{attrs:{"active-class":"list__active white--text"},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},_vm._l((_vm.items),function(ref){
var icon = ref.icon;
var target = ref.target;
var title = ref.title;
return _c(VListItem,{key:target,attrs:{"to":target}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(icon))])],1),_c(VListItemTitle,{staticClass:"menu-title"},[_vm._v(_vm._s(title))])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-list-item class="mb-0 justify-space-between pl-3">
    <v-list-item-content class="pl-2">
      <v-list-item-title class="text-h5">
        <!-- <div class="mr-1 my-3 font-weight-black">Mathison Projects</div> -->
        <div class="image mt-md-0 my-3">
          <img alt="Mathison Projects Logo" src="img/logo-drawer.webp" @click="$router.push('/')" />
        </div>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  export default {
    name: 'DrawerHeader',
    computed: {
      isDarkMode() {
        return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
      },
      dynamicLogo() {
        const lightModeLogo = 'img/logo-drawer.webp';
        const darkModeLogo = 'img/updatedlogo.svg';

        return this.isDarkMode ? lightModeLogo : darkModeLogo;
      }
    }
  }
</script>

<style lang="scss" scoped>
.image {
  cursor: pointer;
  max-width: 250px;
  max-width: 100%;
  height: auto;

  img {
    fill: white;
    max-width: 100%;
    object-fit: cover;
  }
}
</style>
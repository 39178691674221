import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{attrs:{"app":"","height":_vm.isMobile ? 80 : 100,"flat":"","scroll-threshold":"80","color":_vm.bg}},[_c('div',{staticClass:"d-flex align-center navbar px-md-10"},[_c(VAppBarNavIcon,{staticClass:"d-flex d-md-none",attrs:{"aria-label":"nav-icon"},on:{"click":function($event){return _vm.toggleDrawer()}}}),_c('div',{staticClass:"logo d-flex align-center justify-center"},[_c('img',{staticClass:"logo-img",attrs:{"alt":"Mathison Projects logo","src":"img/updatedlogo.svg"},on:{"click":function($event){return _vm.$router.push('/')}}})]),(false)?_c(VMenu,{staticClass:"d-flex d-md-none",attrs:{"open-on-hover":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"languages-button app-bar__item",attrs:{"dark":"","text":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.selectedLanguage.value)+" ")])]}}],null,false,191886415)},[_c(VList,_vm._l((_vm.languages),function(item,index){return _c(VListItem,{key:index,staticClass:"app-bar__item",on:{"click":function($event){return _vm.selectedLanguageHandler(item)}}},[_c(VListItemTitle,[_vm._v(_vm._s(item.text))])],1)}),1)],1):_vm._e(),_c(VToolbarItems,{staticClass:"d-none d-md-flex flex-grow-1 justify-end items"},_vm._l((_vm.items),function(menuItem,index){return _c(VMenu,{key:index,scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [(menuItem.title !== 'Get In Touch')?_c(VBtn,{staticClass:"link",attrs:{"ripple":false,"text":"","to":menuItem.target,"active-class":'activeLink'}},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(menuItem.title))])]):_vm._e()]}}],null,true)})}),1),_c(VBtn,{staticClass:"d-none d-md-flex contactButton",attrs:{"color":"black","dark":"","elevation":"2","large":"","rounded":"","to":{ path: '/contact-us', hash: '#form' }}},[_vm._v(" Contact Us ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-footer class="footer gray" dark>
    <v-container id="content" class="mt-5 mt-lg-10">
      <v-row class="footer-container flex-column flex-md-row">
        <v-col class="flex-md-shrink-1 flex-md-grow-0 d-flex flex-md-column justify-space-between justify-md-start">
          <div>
            <img src="img/footer-logo.svg" id="logo" alt="Mathison Projects Inc. logo" />
          </div>
          <div>
            <h4 class="font-weight-medium">Write Us</h4>
            <p class="font-weight-black">unify@mathisonprojectsinc.com</p>
            <v-row id="social-icons" class="justify-space-around mt-md-16">
              <a v-for="item in socialAccounts" v-bind:key="item.name" :href="item.href" target="_blank">
                <img :alt="`${item.name} icon`" :src="item.imgPath" />
              </a>
            </v-row>
          </div>
        </v-col>
        <v-divider v-if="!isMobile" vertical class="mx-10" />
        <v-col class="flex-md-grow-1">
          <v-row id="specialties-company-partners" class="">
<!--            <v-col cols="4">-->
<!--              <h4 class="mb-2">Our Specialties</h4>-->
<!--              <p v-for="item in specialties" v-bind:key="item">{{ item }}</p>-->
<!--            </v-col>-->
            <v-col cols="6">
              <h4 class="mb-2">Company</h4>
              <a v-for="item in company" v-bind:key="item" :href="item.href" target="_blank" class="d-block  text-decoration-none company-link">{{ item.name }}</a>
            </v-col>
<!--            <v-col cols="4">-->
<!--              <h4 class="mb-2">Partners</h4>-->
<!--              <p v-for="item in partners" v-bind:key="item">{{ item }}</p>-->
<!--            </v-col>-->
          </v-row>
        </v-col>
      </v-row>
      <v-divider v-if="isMobile" class="my-2" />
      <v-row class="mt-md-10">
        <v-col class="text-center secondary-text">
          <p class="grey--text text--lighten-2 mb-md-12">COPYRIGHT &copy; ALL RIGHTS RESERVED &#8212; MATHISON PROJECTS INC</p>
          <p class="text-bold mb-1">AUT VIAM INVENIAM AUT FACIAM</p>
          <p class="font-italic font-weight-light">"I shall either find a way or make one"</p>
        </v-col>
      </v-row>
    </v-container>
    <img src="img/footer-triangles.svg" class="footerImage" />
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      socialAccounts: [
        {
          name: 'facebook',
          href: 'https://www.facebook.com/Mathison-Project-110430574707814/',
          imgPath: 'img/social-facebook.svg'
        },
        {
          name: 'twitter',
          href: 'https://twitter.com/mathisonpro/',
          imgPath: 'img/social-twitter.svg'
        },
        {
          name: 'instagram',
          href: 'https://www.instagram.com/mathisonprojects.inc/',
          imgPath: 'img/social-instagram.svg'
        },
        {
          name: 'linkedin',
          href: 'https://www.linkedin.com/company/mathison-projects-inc/',
          imgPath: 'img/social-linkedin.svg'
        }
      ],
      specialties: [
          'Education', 'Fintech', 'Medical', 'Manufacturing', 'Technology', 'Retail', 'IoT', 'Public & Private Sector'
      ],
      company: [
        {
          name: 'Careers',
          href: 'https://mathisonprojectsinc.com/careers'
        },
        {
          name: 'Terms of Use',
          href: 'https://mathisonprojectsinc.com/terms-of-use'
        },
        {
          name: 'Contact',
          href: 'https://mathisonprojectsinc.com/contact-us'
        },
        {
          name: 'Privacy Policy',
          href: 'https://mathisonprojectsinc.com/privacy-policy'
        },
      ],
      partners: ['Investors']
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify?.breakpoint?.mobile;
    }
  }
};
</script>

<style lang="scss" scoped>
#specialties-company-partners {
  p { margin-bottom: 0 }
  @include screen('md') {
    p { margin-bottom: 16px }
  }
}
footer {
  overflow:hidden;
}
#logo {
  width: 25vw;
  
  @include screen('md') { 
    width: 20.34vw;
  image-rendering: pixelated;
  }
}

.footerImage {
  position: absolute;
  right: 0;
  top: 0;
  max-height: 100%;
  z-index: 0;

  @media only screen and (max-width: 900px) {
    top: 20%;
    right: -5%;
  }
}

#content {
  z-index: 1;
}

#social-icons {
  a img { height: 1.5rem; }
}

.company-link {
  color: white;
}
</style>

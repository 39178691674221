import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[_c('app-bar',{attrs:{"items":_vm.menuItemsList},on:{"onUpdate":_vm.onUpdateHandler}}),_c('drawer',{attrs:{"items":_vm.menuItemsList}}),_c(VMain,{key:_vm.key},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view')],1),_c(VDialog,{attrs:{"value":_vm.cookiesDialog,"max-width":"400px"}},[_c(VCard,{staticClass:"pa-5 text-center"},[_c('div',{staticClass:"text-caption text-left"},[_vm._v("This website uses cookies in order to offer you the most relevant information.")]),_c(VRow,{staticClass:"my-3 justify-space-around"},[_c(VBtn,{staticClass:"pa-3",attrs:{"rounded":"","small":"","color":"teal accent-4"},on:{"click":_vm.acceptCookies}},[_vm._v("Okay")]),_c(VBtn,{staticClass:"pa-3",attrs:{"href":"https://google.com","rounded":"","small":""}},[_vm._v("No Thanks")])],1)],1)],1)],1),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-navigation-drawer
    id="default-drawer"
    v-model="drawer"
    app
    width="260"
    :dark="prefersDark"
    @input="handleInput"
    temporary
  >
    <div class="px-2">
      <drawer-header />

      <v-divider class="mx-3 mb-2" />

      <drawer-list :items="items" />

    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapMutations } from 'vuex'
import DrawerHeader from './DrawerHeader'
import DrawerList from './DrawerList'

  export default {
    name: 'Drawer',
    components: {
      DrawerHeader,
      DrawerList,
    },
    data: () => ({
      prefersDark: false
    }),
    props: {
      items: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      drawer: {
        get: function() { return this.$store.state.ui.drawer },
        set: () => function() { this.$store.commit('ui/toggleDrawer') }
      },
    },
    methods: {
      ...mapMutations('ui', [
        'toggleDrawer'
      ]),
      handleInput(e) {
        if (!e) {
          this.toggleDrawer()
        }
      }
    },
    mounted() {
      if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        this.prefersDark = true;
      }
      window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", isDark => {
        this.prefersDark = isDark.matches;
      })
    }
  }
</script>
<style lang="scss" scoped>
</style>

<template>
  <v-app>
    <app-bar :items="menuItemsList" @onUpdate="onUpdateHandler" />
    <drawer :items="menuItemsList" />
    
    <v-main :key="key">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
      <v-dialog :value="cookiesDialog" max-width="400px">
        <v-card class="pa-5 text-center">
          <div class="text-caption text-left">This website uses cookies in order to offer you the most relevant information.</div>
          <v-row class="my-3 justify-space-around">
              <v-btn @click="acceptCookies" rounded small class="pa-3" color="teal accent-4">Okay</v-btn>
              <v-btn href="https://google.com" rounded small class="pa-3">No Thanks</v-btn>
          </v-row>
        </v-card>
      </v-dialog>
    </v-main>

    <Footer />    
  </v-app>
</template>

<script>
import { apolloClientApi } from '../addons'
import AppBar from './AppBar.vue'
import Drawer from './Drawer.vue'
import Footer from './Footer'

export default {
  name: "DefaultLayoutComponent",
  components: {
    AppBar,
    Drawer,
    Footer
  },
  model: {
    event: 'updateModel'
  },
  data() {
    return {
      drawer: false,
      group: null,
      key: 0,
      cookiesDialog: false
    }
  },
  computed: {
    menuItemsList() {
      return [
        {
          icon: 'mdi-home',
          target: '/',
          title: 'Home'
        },
        {
          icon: 'mdi-account-group',
          target: '/about',
          title: 'About'
        },
        {
          icon: 'mdi-newspaper-variant-outline',
          target: '/press',
          title: 'News'
        },
        {
          icon: 'mdi-home',
          target: '/reimagining-charity',
          title: 'Reimagining Charity'
        },
        {
          icon: 'mdi-tag',
          target: '/products-and-services',
          title: 'Products & Services'
        },
        {
          icon: 'mdi-briefcase-account',
          target: '/careers',
          title: 'Careers'
        },
        {
          icon: 'mdi-email-outline',
          target: '/contact-us#form',
          title: 'Get In Touch'
        }
      ]
    }
  },
  created() {
    apolloClientApi.initAll()
  },
  mounted() {
    if (!window.localStorage.getItem('cookies')) this.cookiesDialog = true
  },
  methods: {
    acceptCookies() {
      window.localStorage.setItem('cookies', true)
      this.cookiesDialog = false
    },
    fireMenuLink(item) {
      if (this.$route.name !== item.target) {
        this.$router.push({ name: item.target })
      }
    },
    toggleDrawer() {
      this.drawer = !this.drawer
    },
    onUpdateHandler() {
      this.key++
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>
<style lang="css" scoped>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 250ms;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
.primary-button {
  background-color: #4CAF50 !important;
}
</style>
